.neutral {
  @apply bg-primary-10 text-dark-80;
}

.error {
  @apply bg-red-20 text-red;
}

.success {
  @apply bg-green-20 text-green-dark;
}

.warning,
.warning-train {
  @apply bg-warning-light text-warning-dark;
}
