/* Base */
.btn {
  @apply outline-none overflow-hidden font-bold text-base leading-20 p-15 rounded-5 border flex items-center justify-center text-center motion-safe:transition-all motion-safe:duration-100 ease-out;

  &[disabled],
  &.disabled {
    @apply bg-gray-80 text-dark opacity-30 pointer-events-none;
  }

  &:not(.disabled) {
    &:active {
      @apply outline-none;
    }
  }

  &.loading {
    @apply relative opacity-30;

    &::before {
      @apply w-full h-full top-0 left-0 block absolute bg-black opacity-20;
      content: '';
      animation-name: translateBg;
      animation-duration: 2000ms;
      transform-origin: left;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
    }
  }

  /* Sizes */
  &.xs {
    @apply p-8 text-14;
  }

  &.sm {
    @apply p-10 text-15;
  }

  &.xl {
    @apply p-20 text-17;
  }

  /* Outline variant */
  &.outline {
    @apply bg-transparent;

    &::before {
      @apply bg-primary;
    }
  }

  /* Plain variant */
  &.plain {
    @apply bg-transparent border-0 underline p-0 text-left justify-start;

    &[disabled],
    &.disabled {
      @apply no-underline opacity-80;
    }

    &:hover,
    &:active,
    &.active {
      @apply no-underline shadow-none;
    }
  }

  /* Color variant */
  &.primary {
    &:not(.outline):not(.plain) {
      @apply bg-primary border-transparent text-white transition ease-in-out duration-300;

      &:not(.loading) {
        &:hover {
          @apply bg-dark;
        }

        &:active {
          @apply bg-dark-80;
        }
      }
    }

    &.outline {
      @apply text-primary border-primary;

      &:hover {
        @apply bg-primary text-white;
      }

      &:active,
      &.active {
        @apply bg-transparent text-primary border-primary;
      }
    }

    &.plain {
      @apply text-primary bg-transparent;
    }
  }

  &.primary-rounded {
    @apply bg-primary rounded-20 border-transparent text-white transition ease-in-out duration-300;

    &:not(.loading) {
      &:hover {
        @apply bg-dark;
      }

      &:active {
        @apply bg-dark-80;
      }
    }

    &[disabled],
    &.disabled {
      @apply bg-primary opacity-70;
    }
  }

  &.primary-rounded-sm {
    @apply bg-primary rounded-15 border-transparent text-white transition ease-in-out duration-300;

    &:not(.loading) {
      &:hover {
        @apply bg-dark;
      }

      &:active {
        @apply bg-dark-80;
      }
    }

    &[disabled],
    &.disabled {
      @apply bg-primary opacity-70;
    }
  }

  &.dark {
    &:not(.outline) {
      @apply bg-dark border-dark text-white;
    }

    &.outline {
      @apply text-dark border-dark;

      &:hover,
      &:active,
      &.active {
        @apply bg-dark border-dark text-white;
      }
    }

    &.plain {
      @apply text-dark bg-transparent;
    }
  }

  &.light {
    &:not(.outline):not(.plain) {
      @apply bg-dark-10 border-transparent text-dark;

      &:not(.loading) {
        &:hover {
          @apply bg-dark-20;
        }

        &:active {
          @apply bg-dark-10;
        }
      }
    }

    &.outline {
      @apply text-dark border-dark-40;

      &:hover,
      &:active,
      &.active {
        @apply bg-dark-10 border-transparent text-dark;
      }
    }

    &.plain {
      @apply text-dark-10 bg-transparent;
    }
  }

  &.light-primary {
    &:not(.outline):not(.plain) {
      @apply bg-primary-20 border-transparent text-dark;

      &:not(.loading) {
        &:hover {
          @apply bg-primary-40;
        }

        &:active {
          @apply bg-primary-20;
        }
      }
    }

    &.outline {
      @apply text-dark border-primary-20;

      &:hover,
      &:active,
      &.active {
        @apply bg-primary-20 border-transparent text-dark;
      }
    }

    &.plain {
      @apply text-primary-20 bg-transparent;
    }
  }

  &.white {
    &:not(.outline):not(.plain) {
      @apply bg-white border-transparent text-dark;

      &:not(.loading) {
        &:hover {
          @apply bg-white;
        }

        &:active {
          @apply bg-white;
        }
      }
    }

    &.outline {
      @apply text-white border-white;

      &:hover,
      &:active,
      &.active {
        @apply bg-white-10 border-transparent text-white;
      }
    }

    &.plain {
      @apply text-white bg-transparent;
    }
  }

  &.white-blue {
    &:not(.outline):not(.plain) {
      @apply bg-white border-transparent text-primary;

      &:not(.loading) {
        &:hover {
          @apply bg-white;
        }

        &:active {
          @apply bg-white;
        }
      }
    }

    &.outline {
      @apply text-white border-white;

      &:hover,
      &:active,
      &.active {
        @apply bg-white-10 border-transparent text-white;
      }
    }

    &.plain {
      @apply text-white bg-transparent;
    }
  }

  &.white-rounded {
    @apply rounded-20;

    &:not(.outline):not(.plain) {
      @apply bg-white border-transparent text-dark;

      &:not(.loading) {
        &:hover {
          @apply bg-white;
        }

        &:active {
          @apply bg-white;
        }
      }
    }

    &.outline {
      @apply text-dark border-dark;

      &:not(.loading) {
        &:hover,
        &:active,
        &.active {
          @apply bg-white-50 border-transparent;
        }
      }
    }

    &.plain {
      @apply text-white bg-transparent;
    }
  }

  &.red {
    &:not(.outline):not(.plain) {
      @apply bg-red-bright border-transparent text-white;

      &:not(.loading) {
        &:hover {
          @apply bg-red-bright;
        }

        &:active {
          @apply bg-red-bright;
        }
      }
    }

    &.outline {
      @apply text-red-bright border-red-bright;

      &:hover,
      &:active,
      &.active {
        @apply bg-red-light border-transparent text-red-bright;
      }
    }

    &.plain {
      @apply text-red-bright bg-transparent;
    }
  }

  &.facebook {
    &:not(.outline) {
      @apply bg-facebook border-facebook text-white;

      &:not(.loading) {
        &:hover,
        &:active {
          @apply bg-facebook-dark border-facebook-dark;
        }
      }
    }

    &.outline {
      @apply text-facebook border-facebook;

      &:not(.loading) {
        &:hover,
        &:active,
        &.active {
          @apply bg-facebook border-facebook text-white;
        }
      }
    }

    &.plain {
      @apply text-facebook bg-transparent;
    }
  }

  &.twitter {
    &:not(.outline) {
      @apply bg-twitter border-twitter text-white;

      &:not(.loading) {
        &:hover,
        &:active {
          @apply bg-twitter-dark border-twitter-dark;
        }
      }
    }

    &.outline {
      @apply text-twitter border-twitter;

      &:not(.loading) {
        &:hover,
        &:active,
        &.active {
          @apply bg-twitter border-twitter text-white;
        }
      }
    }

    &.plain {
      @apply text-twitter bg-transparent;
    }
  }

  &.whatsapp {
    &:not(.outline) {
      @apply bg-whatsapp border-whatsapp text-white;

      &:not(.loading) {
        &:hover,
        &:active {
          @apply bg-whatsapp-dark border-whatsapp-dark;
        }
      }
    }

    &.outline {
      @apply text-whatsapp border-whatsapp;

      &:not(.loading) {
        &:hover,
        &:active,
        &.active {
          @apply bg-whatsapp border-whatsapp text-white;
        }
      }
    }

    &.plain {
      @apply text-whatsapp bg-transparent;
    }
  }

  &.xmas {
    &:not(.outline) {
      @apply bg-xmas border-xmas text-white;

      &:not(.loading) {
        &:hover,
        &:active {
          @apply bg-xmas-dark border-xmas-dark;
        }
      }
    }

    &.outline {
      @apply text-xmas border-xmas;

      &:not(.loading) {
        &:hover,
        &:active,
        &.active {
          @apply bg-xmas border-xmas text-white;
        }
      }
    }

    &.plain {
      @apply text-xmas bg-transparent;
    }
  }
}

.reset-focus:focus,
.reset-focus:focus-visible {
  @apply focus:outline-none focus-visible:outline-none;
}

.focus {
  @apply ring-2 ring-primary;
}

@keyframes translateBg {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(0.99);
  }
}
