@tailwind utilities;

[class^='sm:col-'],
[class^='md:col-'],
[class^='lg:col-'],
[class^='xl:col-'],
[class^='col-'] {
  @apply px-15 w-full;
}

@layer utilities {
  .container {
    margin-right: auto;
    margin-left: auto;
    padding-right: 20px;
    padding-left: 20px;

    @media (min-width: 640px) {
      max-width: 640px;
      padding: 0 30px;
    }

    @media (min-width: 768px) {
      max-width: 768px;
    }

    @media (min-width: 1024px) {
      max-width: 970px;
    }

    @media (min-width: 1280px) {
      max-width: 1170px;
    }
  }

  .tabs {
    width: 100%;
    margin: auto;
    padding: 0 30px;
    padding-right: 0;

    @media (min-width: 768px) {
      max-width: 768px;
    }

    @media (min-width: 1024px) {
      max-width: 970px;
    }

    @media (min-width: 1280px) {
      max-width: 1170px;
    }
  }
  .container-tab {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .tab-title {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 23px;
    color: #0f2041;
    margin-bottom: 2px;
  }
  .tab-title-unselected {
    color: rgba(15, 32, 65, 0.6);
  }
  .tab-subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba(15, 32, 65, 0.6);
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .col-1 {
    max-width: 8.333333%;
    flex: 0 0 8.333333%;
  }
  .col-2 {
    max-width: 16.666667%;
    flex: 0 0 16.666667%;
  }
  .col-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }
  .col-4 {
    max-width: 33.333333%;
    flex: 0 0 33.333333%;
  }
  .col-5 {
    max-width: 41.666667%;
    flex: 0 0 41.666667%;
  }
  .col-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .col-7 {
    max-width: 58.333333%;
    flex: 0 0 58.333333%;
  }
  .col-8 {
    max-width: 66.666667%;
    flex: 0 0 66.666667%;
  }
  .col-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }
  .col-10 {
    max-width: 83.333333%;
    flex: 0 0 83.333333%;
  }
  .col-11 {
    max-width: 91.666667%;
    flex: 0 0 91.666667%;
  }
  .col-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }
}
