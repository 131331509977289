.auth {
  @apply text-dark;
  background-image: linear-gradient(135.89deg, #c1c6db 14.51%, #a7abcf 96.05%);
  background-position: top right;
  background-size: auto;
  background-repeat: no-repeat;
}

.panelContainer {
  max-width: 790px;
}

.overlayClose {
  @apply bg-dark-5 motion-safe:duration-200 ease-out;
}

.overlayOpen {
  @apply bg-dark-80 motion-safe:duration-300 ease-in;
}

.menuClose {
  @apply translate-y-60 motion-safe:duration-200 ease-out opacity-0;
}

.menuOpen {
  @apply translate-y-0 motion-safe:duration-300 ease-in opacity-100;
}

@screen md {
  .menuClose {
    @apply translate-x-60 translate-y-0;
  }

  .menuOpen {
    @apply translate-x-0;
  }
}
