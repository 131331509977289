@tailwind base;
@tailwind components;
@tailwind utilities;

@import './grid.css';
@import './font.css';

@layer utilities {
  .text-shadow {
    text-shadow: 0px 1px 2px rgba(82, 131, 173, 0.25);
  }
  .text-shadow-light {
    text-shadow: 0px 1px 10px rgba(255, 255, 255, 0.75);
  }

  .guideGradient {
    background-image: linear-gradient(
      269.84deg,
      #75b8c9 4.19%,
      #9ac975 29.3%,
      #aeab8e 52.44%,
      #d539a9 73.61%,
      #7578c9 98.72%
    );
  }
}

@layer base {
  /* Common */
  html {
    @apply leading-normal h-app font-medium;
  }

  body {
    @apply font-sans antialiased text-dark text-base h-app;
  }

  /* Typography */
  .title-1 {
    @apply text-50 leading-54 font-bold;
  }

  .subtitle-1 {
    @apply text-40 leading-50;
  }

  .subtitle-1-medium {
    @apply text-40 leading-50;
  }

  .title-2 {
    @apply text-40 leading-44 font-bold;
  }

  .subtitle-2 {
    @apply text-30 leading-40;
  }

  .subtitle-2-medium {
    @apply text-30 leading-40;
  }

  .title-3 {
    @apply text-30 leading-40 font-bold;
  }

  .subtitle-3 {
    @apply text-26 leading-36;
  }

  .subtitle-3-medium {
    @apply text-26 leading-36;
  }

  .title-4 {
    @apply text-26 leading-36 font-bold;
  }

  .subtitle-4 {
    @apply text-22 leading-26;
  }

  .subtitle-4-medium {
    @apply text-22 leading-26;
  }

  .title-5 {
    @apply text-22 leading-34 font-bold;
  }

  .subtitle-5 {
    @apply text-18 leading-24;
  }

  .subtitle-5-medium {
    @apply text-18 leading-24;
  }

  .title-6 {
    @apply text-18 leading-33 font-bold;
  }

  .subtitle-6 {
    @apply text-base leading-23;
  }

  .subtitle-6-medium {
    @apply text-base leading-23;
  }

  button {
    @apply focus:outline-none;
  }

  .card-simple-shadow {
    @apply rounded-5 bg-white p-30 border border-dark-10;
  }
}
.bg-search-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  height: 27vh;
  width: 100vw;
}
.bg-linear {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 102%;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 65%);
  transform: rotate(-180deg);
}
.search-header-nav {
  position: fixed;
  background-color: transparent;
  z-index: 3;
  height: 77px;
}
.search-header-nav-line {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.search-nav-blur {
  backdrop-filter: saturate(80%) blur(5px);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.search-nav-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.8;
  background-image: url('../assets/img/search.png');
  background-position: top;
  background-size: 200% 230%;
}
.search-header-button {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  padding: 13px;
  position: relative;
  width: 43px;
  height: 43px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.search-header-button-bg {
  border-radius: 5px;
  position: absolute;
  /* z-index: 50; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(15, 32, 65, 0.4);
  opacity: 0.3;
}
.search-header-texts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}
.search-filter-container-button {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 75px;
  left: 0;
  background: linear-gradient(
    0deg,
    #ffffff 23.19%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 10;
  display: flex;
  justify-content: center;
  gap: 10px;
}
.search-filter-button {
  height: 58px;
  padding-left: 25px;
  padding-right: 25px;
  background: #316ce2;
  border-radius: 100px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-style: normal;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  filter: drop-shadow(0px 14px 24px #bdcee7);
  margin-bottom: 10px;
}

.search-modify-button {
  height: 58px;
  padding-left: 25px;
  padding-right: 25px;
  background: white;
  border-radius: 100px;
  box-shadow: 0px 14px 24px 0px #bdcee7;
  border: 1px solid rgba(15, 32, 65, 0.1);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-style: normal;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  color: #0f2041;
  margin-bottom: 10px;
}

.search-close-button {
  background: rgba(15, 32, 65, 0.15);
  border-radius: 5px;
  padding: 13px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  margin-left: 20px;
  margin-top: 20px;
}
.filter-logo {
  margin-right: 8px;
}
.filter-modal-mobile {
  height: auto;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.border-destination {
  border: 1px solid #e9e9e9;
  border-radius: 5px;
}
.bg-dark-blue {
  background: rgba(15, 32, 65, 0.8);
}
.search-header-button-isExpand {
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 13px;
  position: relative;
  width: 43px;
  height: 43px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.search-header-button-isExpand > svg > path {
  stroke: rgba(15, 32, 65, 0.8);
  fill: rgba(15, 32, 65, 0.8);
}

.toggle {
  align-items: center;
  margin-right: 19px;
}
.toggle-switch {
  width: 35px;
  height: 20px;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.toggle-switch-checkbox {
  display: none;
}
.toggle-switch-label {
  border: 0 solid #bbb;
  border-radius: 20px;
  margin: 0;
}
.toggle-switch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.15s ease-in 0s;
}
.toggle-switch-inner:before,
.toggle-switch-inner:after {
  float: left;
  width: 50%;
  height: 20px;
  padding: 0;
  line-height: 20px;
  font-size: 14px;
  color: white;
  font-weight: bold;
  box-sizing: border-box;
}
.toggle-switch-inner:before {
  content: '';
  text-transform: uppercase;
  padding-left: 10px;
  background-color: #316ce2;
  color: #fff;
}
.toggle-switch-disabled {
  background-color: rgba(232, 232, 232, 0.8);
  cursor: not-allowed;
}
.toggle-switch-disabled:before {
  background-color: rgba(232, 232, 232, 0.8);
  cursor: not-allowed;
}
.toggle-switch-inner:after {
  content: '';
  text-transform: uppercase;
  padding-right: 10px;
  background-color: rgba(232, 232, 232, 0.8);
  color: #fff;
  text-align: right;
  box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.6),
    0px 1px 2px rgba(255, 255, 255, 0.8),
    inset 0px 1px 2px rgba(106, 117, 139, 0.25);
}
.toggle-switch-switch {
  display: block;
  width: 16px;
  height: 16px;
  margin: 2px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 14px;
  border: 0 solid rgba(232, 232, 232, 0.8);
  border-radius: 20px;
  transition: all 0.15s ease-in 0s;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
  margin-left: 0;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
  right: 0px;
}

.toggle-switch-switch.block {
  box-shadow: 0px 1px 2px rgba(106, 117, 139, 0.25);
}

.toggle-text {
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  color: #0f2041;
}
.pr-17 {
  padding-right: 17px;
}
.vertical-line {
  width: 13px;
  height: 0px;
  border: 1px solid #e4e6f1;
  transform: rotate(-90deg);
}

/* .fill-blue path {
  fill: #316ce2;
}

.fill-primary path {
  fill: green;
} */

@media only screen and (max-width: 600px) {
  .tabs {
    padding: 0 20px;
  }
}

.container-10 {
  max-width: 1040px;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: auto;
  margin-right: auto;
}

.container-12 {
  max-width: 1250px;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: auto;
  margin-right: auto;
}
