.banner {
  border-radius: 30px;
  background: linear-gradient(
    70deg,
    rgba(15, 32, 65, 0.4) 10.31%,
    rgba(15, 32, 65, 0) 122.46%
  );
  box-shadow: 0px 14px 34px 0px rgba(43, 57, 96, 0.46);
  backdrop-filter: blur(6px);
  transition: all 0.3s ease-in-out;
}

.banner:hover {
  box-shadow: 0px 14px 34px 0px rgba(43, 57, 96, 0.6);
}

.bannerGift {
  border-radius: 30px;
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0px 14px 34px 0px rgba(6, 6, 0, 0.2);
  backdrop-filter: blur(6px);
  transition: all 0.3s ease-in-out;
}

.bannerGift {
  cursor: default;
}
